<template>

<v-dialog
    v-model="dialog"
    width="100%"
    scrollable
    > 
    <v-card :height="vsh-100" v-if="dialog">
        <v-toolbar color="primary" flat dark>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon size="40">mdi-clipboard-text-clock</v-icon>
                </v-list-item-avatar>
                <v-list-item-content v-if="day_shift">
                    <v-list-item-title>{{ MBS.actions.TEXT_UP(day_shift.outlet_name +" Sales Report")}} </v-list-item-title>
                    <v-list-item-subtitle>{{ day_shift.operating_date_ }}</v-list-item-subtitle> 
                </v-list-item-content>
            </v-list-item>
        <v-spacer></v-spacer>
        <v-btn  icon @click="dialog=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <mbs-page-tab 
            v-model="currentTab"
            :items="page_tabs"/>  

        <mbs-page-spinner v-if="downloading"
            :show="true"/> 
        <div v-else> 
            <mbs-item-manager v-if="true"
                :add_vertical_lines="true"
                v-model="input_table"
                :table_tabs="currentTab?null:tableTabs"
                :top_label="false"
                :filter_date="false"
                :filter_date_only_display="true"
                :filter_date_value="'local_created_at'"
                :filter_default_days="'90'"
                :name_items="currentTab?'Issued Receipt':(NAME_ITEMS)"
                :name_item="NAME_ITEM"
                :value_items="VALUE_ITEMS"
                :value_item="VALUE_ITEM"
             
                :header_items="HEADER_ITEMS" 
                :data_items="TableData"   
                :is_joined="true"  
                 
                :total_item_values="TOTAL_ITEM_VALUES"   
    
                :table_actions="table_actions" 
                @SELECT_TABLE_ACTION="TABLE_ACTION"
                @SELECT_ITEM_ACTION="ITEM_ACTION"
            />
        </div>
    </v-card>
</v-dialog>
     
</template> 
<script> 

    import DATA from "../../plugins/DATA";
    import DB from "../../plugins/DB";
    import {mapState} from "vuex" 
    import firebase from 'firebase/app' 
    import 'firebase/database'  

    const PAGE_NAME = "DAY-SHIFT"

    export default {
        props:['value','day_shift'],
        data(){
            return{        
                dialog:this.value, 
                page_tabs:[
                    {name:"Sold Items"},
                    {name:"Issued Receipts"},
                ],
                NAME_ITEMS:"Sales Items",
                NAME_ITEM:"Sales Item",
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS_SALES.value,
                currentTableTab:null,
                currentTab:1,
                filter_date:null,
                filteredDate:null, 
                input_table:null, 
                tableTabs:[
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ],
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},   
                    // {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Item"},   
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'sold_quantity'},
                    {name:'Total Cost Price',value:'sold_cost_price',show_value:'sold_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ],
                REF_DAYS_SHIFTS_SALES:null,
                // SalesOrders:null,
                downloading:false,
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
               
               
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            this.dialog = this.value
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],

                SalesOrders: state=> state.items[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],
                JoinedSalesOrders: state=> state.join[(DATA.ITEMS.DAY_SALES.values).toUpperCase()], 
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()], 
            }), 

            //data
            ItemsData(){
                const Items = this.Items
                const JoinedItems = this.JoinedItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            LocalSelectedSalesItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedSalesItemsData(){   
                const items = this.LocalSelectedSalesItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            }, 
            CheckedItems(){
                const SELECTED_ITEMS = this.SELECTED_ITEMS
                if(!SELECTED_ITEMS){return null}
                let filtered = SELECTED_ITEMS.filter(item=>item.checked)
                return filtered
            },

            SELECTED_ITEMS(){
                const settings = this.CompanySettings
                const selling_list_descend = settings?settings.selling_list_descend:null
                const items = this.LocalSelectedSalesItemsData 
                const reversed = this.ReversedLocalSelectedSalesItemsData  
                return selling_list_descend?reversed:items
            },
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            TableTabs(){
                return  [
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ]
            },

            

            moment(){  
                return this.MBS.date.moment
            }, 
            HEADER_ITEMS1(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"Barcode",value:'key',show:false},   
                    {id:0,name:"date",value:'local_created_at_',show:true},           
                    {id:0,name:"transaction",value:"transaction_key",show:true},     
                    {id:0,name:"Item",value:"name",show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'right',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Barcode",value:'code',show:false},           
                    {id:0,name:"item",value:"name",show:true},     
                    {id:0,name:"Transactions",value:"number_of_transactions",align:'center',show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS3(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Payment",value:"payment_method_name",show:true},  
                    {id:0,name:"Day Shift Code",value:'day_shift_key',show:false},   
                    {id:0,name:"Cashup Code",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:true},         
                    {id:0,name:"Till",value:"till_name",show:false},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:true},   
                    {id:0,name:"Sold Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        // {name:"log",action:"log",icon:"mdi-plus"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                if (tab == 0) {
                    if (table_tab==0) {
                        return this.HEADER_ITEMS1
                    }else{
                        return this.HEADER_ITEMS2
                    }
                    
                } else {
                    return this.HEADER_ITEMS3
                }
            }, 
            TableData(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                let sales_orders = this.SalesOrdersData
                let per_transactions = this.PerTransactionItems
                let aggregated = this.AggregatedItems
                let items = null
                if (tab == 0) {
                    if (table_tab==0) {
                        items = per_transactions
                    }else{
                        items = aggregated
                    }
                    
                } else {
                    items = sales_orders
                }
                console.log(sales_orders,'sales_orders......');
                console.log(per_transactions,'per_transactions......');
                console.log(aggregated,'aggregated......');
                console.log(items,'item......');
                console.log(tab,'tab......');
                return items
            }, 
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders  
                if(!items){return null}
                let list = [...items] 
                return list
            }, 
            // SalesOrdersData(){ 
            //     let SalesOrders = this.SalesOrders
            //     console.log(SalesOrders,'SalesOrders..........');
            //     let JoinedSalesOrders = this.MBS.actions.JOIN_SALE_ORDERS(SalesOrders)
            //     let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders 
            //     if(!items){return null}
            //     let list = [...items]
            //     list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
            //     console.log(list,'l***********');
            //     return list
            // }, 
            AggregatedItems(){
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount

                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        sold_quantity:sold_quantity,
                                        sold_cost_price:sold_cost_price,
                                        sold_discount:sold_discount,
                                        sold_price:sold_price,
                                        total_sold_price:total_sold_price,
                                        number_of_transactions:1,
                                        
                                        sold_quantity_:this.MBS.actions.money( sold_quantity),
                                        sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                        sold_discount_:this.MBS.actions.money( sold_discount),
                                        sold_price_:this.MBS.actions.money( sold_price),
                                        total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    })
                                } else {
                                    items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                    items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                    items[index].sold_discount = items[index].sold_discount+sold_discount
                                    items[index].sold_price = items[index].sold_price+sold_price
                                    items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                    items[index].number_of_transactions = items[index].number_of_transactions+1
                                    
                                    items[index].sold_quantity_ = this.MBS.actions.money( items[index].sold_quantity)
                                    items[index].sold_cost_price_ = this.MBS.actions.money( items[index].sold_cost_price)
                                    items[index].sold_discount_ = this.MBS.actions.money( items[index].sold_discount)
                                    items[index].sold_price_ = this.MBS.actions.money( items[index].sold_price)
                                    items[index].total_sold_price_ = this.MBS.actions.money( items[index].total_sold_price)
                                }    
                            });
                            
                        }
                    }     
                });  
                return items
            },
            PerTransactionItems(){ 
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {  
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true 
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                // let index = items.findIndex(it=>{
                                //     return it.item_key  == item.item_key
                                // }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
                                

                                items.push({
                                    ...item, 
                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    
                                    sold_quantity_:this.MBS.actions.money( sold_quantity),
                                    sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                    sold_discount_:this.MBS.actions.money( sold_discount),
                                    sold_price_:this.MBS.actions.money( sold_price),
                                    total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    
                                    transaction_key:order.key,

                                    local_created_at:order.local_created_at,
                                    local_created_at_:order.local_created_at_,
                                    local_created_at_date:order.local_created_at_date,
                                    local_created_at_time:order.local_created_at_time,
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            },
            CalculatedItems(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.PerTransactionItems
                }else{
                    return this.AggregatedItems
                }
            }
              
        },
        methods:{ 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") { 
                        this.FI_INITIATE()
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            FI_INITIATE(){ 
                try {
                    let day_shift = this.day_shift
                    let day_shift_key = day_shift?day_shift.key:null
                    if (day_shift_key) {
                        this.downloading = true
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales+day_shift_key,true)
                        console.log(path,'path....... .......***');
                        this.$store.dispatch("fi_get_day_sales",{
                            path:path
                        }).then(res=>{
                            this.downloading = false
                            console.log(res,'res.........');
                        }).catch(error=>{
                            this.downloading = false
                            console.log(error,'error............');
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FI_INITIATE',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            FI_CLOSE(){ 
                try { 
                    this.$store.dispatch("off_refs",{
                        name:DATA.ITEMS.DAY_SALES.values
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FI_CLOSE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            
            //---------------[MAIN FUNCTIONS]------------   
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{  
                             
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+""+value 
                            }
                        }else if (field_value == "current_selected_item_discount") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.discount = (""+this.current_selected_item.discount).slice(0,-1)
                            } else {
                                this.current_selected_item.discount =this.current_selected_item.discount+""+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1);

                            console.log(this.input,'input......');
                            console.log(value,'value......');
                        }
                        
                    }
                }
            }, 
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
                this.FI_CLOSE()  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)   
            },
            SELECTED_ITEMS(value){
                 
            },
            dialog(value){
                this.$emit('input', value)
                if (value) {
                    this.FI_INITIATE()
                    console.log('opened**********');
                } else {
                    this.FI_CLOSE()
                    console.log('closed**********');
                }
            },
            value(value){
                this.dialog = value
            },
            input_table(value){ 
                if (value) {
                    this.filteredDate = value.filter_date
                    this.currentTableTab = value.current_table_tab
                } 
            } 
        } 

    }
</script> 